import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import {
    ADVANTAGES,
    BUSINESS_BENEFITS,
    COMPANY_LOGOS,
    LATEST_RELATED_ARTICLES_SETTINGS,
    PERKS_LIST,
    SECTIONS_ID,
    SECTIONS_TRANSLATION,
    TECHNOLOGICAL_BENEFITS,
} from 'views/node-js/constants';
import { OpenJsSection } from 'views/node-js/open-js-section';
import { CONSTANTS } from 'constants/styles/constants';
import { FooterSection } from 'modules/footer-section';
import { ObjectiveAdvantagesSection } from 'modules/objective-advantages-section';
import { BookConsultationSection } from 'modules/technologies/book-consultation-section';
import { BusinessBenefitsSection } from 'modules/technologies/business-benefits-section';
import { AnimatedBusinessBenefits } from 'modules/technologies/business-benefits-section/components/animated-business-benefits';
import { CodeConsultingSection } from 'modules/technologies/code-consulting-section';
import { HeaderSection } from 'modules/technologies/header-section';
import { AnimatedDevelopment } from 'modules/technologies/header-section/components/animated-native-development';
import { HireDevelopersSection } from 'modules/technologies/hire-developers-section';
import { RealResultsSection } from 'modules/technologies/real-results-section';
import { SectionsNavigationBar } from 'modules/technologies/sections-navigation-bar';
import { TechnologicalBenefitsSection } from 'modules/technologies/technological-benefits-section';
import { WorkWithMRSection } from 'modules/technologies/work-with-mr-section';
import { array } from 'prop-types';
import BusinessBenefits from 'svgs/technologies/node-js/business-benefits.svg';
import NodeJSDevelopment from 'svgs/technologies/node-js/node-js-development.svg';

const Container = styled.div`
    width: 100%;
    padding-top: ${CONSTANTS.MARGIN.PAGE};
`;

const Props = {
    faqData: array.isRequired,
};

export const NodeJsView = ({ faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <HeaderSection
                title="node-js.header.title"
                descriptions={['node-js.header.description']}
                img={
                    <AnimatedDevelopment
                        containerCss={css`
                            ${CONSTANTS.MEDIA.min1024max1200`
                                > svg {
                                    width: 32rem;
                                }
                            `};
                        `}
                    >
                        <NodeJSDevelopment />
                    </AnimatedDevelopment>
                }
                alt="node-js.header.imgAlt"
            />
            <SectionsNavigationBar
                data={SECTIONS_TRANSLATION}
                ids={SECTIONS_ID}
            />
            <CodeConsultingSection
                id={SECTIONS_ID[0]}
                title="node-js.topics.codeConsulting"
                subTitle="node-js.codeConsulting.subTitle"
                descriptions={[
                    'node-js.codeConsulting.descriptions.0',
                    'node-js.codeConsulting.descriptions.1',
                    'node-js.codeConsulting.descriptions.2',
                ]}
            />
            <BookConsultationSection />
            <RealResultsSection
                id={SECTIONS_ID[1]}
                title="node-js.topics.realResults"
                description="node-js.realResults.description"
                logos={COMPANY_LOGOS}
                margin={true}
            />
            <OpenJsSection />
            <TechnologicalBenefitsSection
                id={SECTIONS_ID[2]}
                data={TECHNOLOGICAL_BENEFITS}
                title="node-js.technologicalBenefits.title"
                paragraph={formatMessage({
                    id: 'node-js.technologicalBenefits.subTitle',
                })}
            />
            <ObjectiveAdvantagesSection
                id={SECTIONS_ID[3]}
                data={ADVANTAGES}
                title="node-js.topics.objectiveAdvantages"
                subTitle="node-js.objectiveAdvantages.subTitle"
                rootPath="node-js.objectiveAdvantages.reasons"
            />
            <HireDevelopersSection
                id={SECTIONS_ID[4]}
                title="node-js.topics.hireDevelopers"
                description="node-js.ourDevelopers"
            />
            <BusinessBenefitsSection
                id={SECTIONS_ID[5]}
                logo={
                    <AnimatedBusinessBenefits>
                        <BusinessBenefits />
                    </AnimatedBusinessBenefits>
                }
                data={BUSINESS_BENEFITS}
                title="node-js.topics.businessBenefits"
                description="node-js.businessBenefits.description"
            />
            <WorkWithMRSection
                id={SECTIONS_ID[6]}
                title="node-js.workWithMobileReality.title"
                firstRowDescriptions={[
                    'node-js.workWithMobileReality.atMobileReality',
                    'node-js.workWithMobileReality.agileDevelopment',
                ]}
                secondRowDescriptions={[
                    'node-js.workWithMobileReality.collaborativeApproach',
                ]}
                perksData={PERKS_LIST}
                firstRowAlt={formatMessage({
                    id: 'node-js.workWithMobileReality.firstRowAlt',
                })}
                secondRowAlt={formatMessage({
                    id: 'node-js.workWithMobileReality.secondRowAlt',
                })}
            />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </Container>
    );
};

NodeJsView.propTypes = Props;
